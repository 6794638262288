<template>
  <!--srcviewscompanydetailsBusinessActivitiesSupplyChainvuehtmlStart-->

  <div id="defaultId1 " style="padding-bottom:20px">
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false">
      <div>
        <h2 class="operations-head flex_center_between_box margin-bottom20">
          <span class="head-titel detail_title_20">Product import trade data and trends</span>
          <!-- <el-date-picker v-model="datePicker" @change="SelectPicker" :readonly="true" style="width: 20rem;height: 3.571428rem;"
          type="years" range-separator="" start-placeholder="Start date" value-format="yyyy-MM"
          end-placeholder="End date"></el-date-picker> -->
          <el-select style="width:  14.2857143rem;"  v-model="dateImportPicker" multiple :multiple-limit="2" placeholder="Please Select"  @change="SelectPicker">
            <el-option v-for="item in Yearoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          
        </h2>
        <div class="flex_center_between_box">

          <div class="Products_lesft" style="height: auto; width: 100%;">
            <div ref="SupplyChainEchart" style="width: 98%; height: 100%"></div>
          </div>
          <div class="table-biao table-biao-left" style="position: relative;">

            <el-table :data="importTableData" ref="table" empty-text="No Data" style="width: 100%"
              class="table_height_30 table_font_size_14 table_contentHeight_40">
           
              <el-table-column prop="date" label="Year" align="left" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.date }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="Amount" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  
                  <span :class="scope.row.amount.includes('-')?'table-content table-contentred':'table-content'">USD {{ scope.row.amount | FilterNum }}</span>
                </template>
              </el-table-column>



             
            </el-table>

          </div>
        </div>

      </div>
    </gui_card>
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false" style="margin-top: 10px;">
      <div>
        <h2 class="operations-head flex_center_between_box margin-bottom20">
          <span class="head-titel detail_title_20">Country Trade Data</span>
          <el-select style="width:  14.2857143rem;"  v-model="Importyear" placeholder="Please Select" @change="getmportDatabottom">
            <el-option v-for="item in Yearoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </h2>
      <div class="flex_center_between_box">
        <div style="width: 48%;">
          <p class="CountryUsd" style="border-top: 1px solid #ccc;"><span class="CountryUsdspan" >Total Trade:</span>{{ CountryObj?.totalTrade }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Total Export:</span>{{ CountryObj?.totalExport }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Total Import:</span>{{ CountryObj?.totalImport }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Trade Net Balance:</span>{{ CountryObj?.tradeNetBalance }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Trade Gap:</span>{{ CountryObj?.tradeGap }}</p>
        </div>
        <div style="width: 48%;">
          <p class="CountryUsd" style="border-top: 1px solid #ccc;"><span class="CountryUsdspan">Trade Goods Exports:</span>{{ CountryObj?.tradeGoodsExports }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Trade Goods Imports:</span>{{ CountryObj?.tradeGoodsImports }}</p>
          <p class="CountryUsd"><span class="CountryUsdspan">Trade Serviecs Imports:</span>{{ CountryObj?.tradeServicesImports }}</p>
          <p class="CountryUsd" ><span class="CountryUsdspan">Trade Serviecs Exports:</span>{{ CountryObj?.tradeServicesExports }}</p>
          <p class="CountryUsd" ><span class="CountryUsdspan"></span></p>
        </div>
      </div>

      </div>
    </gui_card>

    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>

<script>
import { showTradeDataTrends,showCountryTradeData } from '@/api/companyApi.js';
import rectcard from "@/components/rectCard/index.vue";
import content_text from "@/components/text_content/index.vue";
import ReLogin from "../../../../components/ReLogin.vue";
import gui_card from "@/components/gui_card/gui_card.vue";
import { nextTick } from 'vue';
export default {
  name: "",
  // props: {},
  components: {
    gui_card,
    // gui_card end

    rectcard,
    content_text,
    ReLogin,
  },
  data() {
    return {
      data: {
        links: [],
        nodes: [],

      },
      Importyear:'2024',
      Yearoptions: [
        {
          label: '2025',
          value: '2025',
        },
        {
          label: '2024',
          value: '2024',
        },
        {
          label: '2023',
          value: '2023',
        },
        {
          label: '2022',
          value: '2022',
        },
        {
          label: '2021',
          value: '2021',
        },
        {
          label: '2020',
          value: '2020',
        },
        {
          label: '2019',
          value: '2019',
        },
        {
          label: '2018',
          value: '2018',
        },
        {
          label: '2017',
          value: '2017',
        },
        {
          label: '2016',
          value: '2016',
        },
        {
          label: '2015',
          value: '2015',
        },
        {
          label: '2014',
          value: '2014',
        },
        {
          label: '2013',
          value: '2013',
        },

      ],
      datePicker: [],
      dateImportPicker: ['2014','2024'],
      Exportyear: '2024',
      activeIEports: 'Imports',
      CountryObj:{},

      ExporttableData: [],
      importTableData: [],
      reLoginVis: false,

    };
  },
  mounted() {
    this.getmportData();
    this.getmportDatabottom()

  },
  methods: {
    ceckIEports(row) {
      this.activeIEports = row;
    },
    default() { },

    ChangeEcportDate() {

      this.getexportData()
    },

    SelectPicker(){

     this.dateImportPicker = this.dateImportPicker.sort(function (a, b) {
                return a - b;
            });
      this.getmportData ()
    },
    getmportData() {
      showTradeDataTrends({
        country: this.$route.query.id3a,
        startYear: this.dateImportPicker[0],
        endYear: this.dateImportPicker[1]

      }).then(res => {
        if (res.code == 200) {
          this.importTableData = res.data
        }
        this.suppleEchart();
      })


    },
    getmportDatabottom(){
      showCountryTradeData({
        tradeYearMonth:this.Importyear,
        country: this.$route.query.id3a,
      }).then(Res=>{
        if(Res.code==200){
          this.CountryObj=Res.data
        }
      })
    },
    suppleEchart() {
      let that = this;

      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',

          }
        },
        grid: {
          left: '0%', // 设置左侧边距
          right: '0%',
          top: '8%',
          bottom: '0%',
          containLabel: true
        },
        legend: {
          right: '10px',
          top: '0px',
          //  width: '200px'
          // 其他图例配置项
          // ...
        },
        xAxis: {
          type: 'category',
          data: that.importTableData.map(item => item.date),
          axisTick: {
            show: false // 不显示 x 轴上的间隔点
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,

              color: '#8497AB'
            }
          },
          axisLabel: {
            color: '#1A2332', // 设置字体颜色
            fontSize: 14, // 可以在这里设置字体大小
            fontWeight: 'normal', // 字体粗细
            fontFamily: 'DMSans, DMSans' ,// 字体类型,
            }
        },
        yAxis: [
          {
            type: 'value',
            // splitNumber: 4,
            // inverse: true,
            axisLine: {
              show: true,
              // 横坐标轴颜色
              lineStyle: {
                width: 1,

                color: '#8497AB'
              }
            },
            // 背景虚线
            splitLine: {
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: '#8398BB'
              }
            },
            axisLabel: {
              color: '#1A2332', // 设置字体颜色
            fontSize: 14, // 可以在这里设置字体大小
            fontWeight: 'normal', // 字体粗细
            fontFamily: 'DMSans, DMSans' ,// 字体类型,
              formatter: '{value} '
            }
          },

          {
            type: 'value',
            // position: 'right',




          }
        ],
        series: [
          {
            name: 'Total Import',
            type: 'bar',
            barWidth: '30',
            itemStyle: { color: '#009EA7' },
            emphasis: {
              itemStyle: {
                color: '#009EA7',
              },
            },
            data: that.importTableData.map(item => item.totalExport)
          },
          {
            name: 'Total Export',
            type: 'bar',
            barWidth: '30',
            itemStyle: { color: '#FFA000' },
            emphasis: {
              itemStyle: {
                color: '#FFA000',
              },
            },
            data: that.importTableData.map(item => item.totalImport)
          },

        ]
      };
      this.echartsBox.setOption(option);
      this.$nextTick(() => {
        this.echartsBox.resize();

      })
    },

  },
  computed: {},

};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}

.table-biao {
  height: auto;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 0.2857142857142857rem;
  margin-top: 1.4285714285714286rem;
}

.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
  padding-left: 1.4285714285714286rem !important;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
  font-family: 'DMSans Bold';
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

@media screen and (max-width: 1441px) {
  .contclass {
    font-size: 14px;

  }
}


.table_add {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_add_Text {
  font-family: 'DMSans Bold';
  text-align: left;
}

.table-content {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  display: inline-block;
  line-height: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.impact_status {
  width: 2.2857142857142856rem;
  height: 2.2857142857142856rem;
  text-align: center;
  border-radius: 50%;
}

.impact_status img {
  width: 1.1428571428571428rem;
  height: 1.1428571428571428rem;
  margin-top: 0.5rem;
}

.impact_status.green {
  background: #55b419;
}

.impact_status.hui {
  background: #ccc;
}

.impact_status.blue {
  background: #1290c9;
}

.impact_status.red {
  background: #ff7600;
}

.input-str {
  position: absolute;
  top: 0.2857142857142857rem;
  left: 13.214285714285714rem;
  z-index: 99;
  text-align: 7.142857142857143rem;
  width: 26%;
}

.input-str /deep/ .el-input__inner {
  margin-top: -0.14285714285714285rem;
}

/deep/.el-input__inner:focus {
  border: 0.07142857142857142rem solid #8497AB;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}

.Products_lesft {
  width: 69%;
  margin-left: 3rem;
}

.table-biao {
  width: 33%;
}
.CountryUsd{
  width: 100%;
  height: 3.57142857rem;
  color: #1A2332;
  font-size: 1.285714rem;
  display: flex;
border-bottom: 1px solid #CCCCCC;
  align-items: center;
}
.CountryUsdspan{
width: 30%;
background: #F2F5F9;
font-size: 18px;
font-family: 'DMSans Bold' !important;
display: inline-block;
height: 100%;
display: flex;
align-items: center;

padding-left: 2.1428714286rem;
margin-right: 2.1428714286rem;
}
.table-biao-left /deep/ .el-table td.el-table__cell {
    border-right: none;
    height: 3.71rem;
}
/deep/  .el-tag.el-tag--info {
  background-color: transparent !important;
    border-color: transparent !important;
    color: #1A2332;
    font-size: 1.285714rem;
}
/deep/  .el-icon-close{
  display: none !important;
}

.table-contentred{
  color: #F56B6B !important;
}
</style>
